window.executeShortcode = function ( element_id, arg, nonce ) {
	
	// Make an AJAX request to execute the shortcode
	var xhttp = new XMLHttpRequest();

	xhttp.onreadystatechange = function( ) {

		var frontend = document.getElementById('front_' + element_id );
    	var front_img = document.getElementById('quizimg' + element_id );
		front_img.className = 'rainbow';
		
		if (this.readyState === 4 && this.status === 200) {
			var response = this.responseText;
    		var parser = new DOMParser();
    		var doc = parser.parseFromString(response, 'text/html');
    		//var content = doc.getElementsByTagName('div').innerHTML; // Assuming there's an element with the ID 'content' in the AJAX response
		
			// Execute any JavaScript code included in the response
			var scriptTags = doc.getElementsByTagName('script');
			for (var i = 0; i < scriptTags.length; i++) {
			eval(scriptTags[i].innerHTML);
			}

			// Update the HTML content of an element
			document.getElementById('result_' + element_id ).innerHTML = response;
		    var result = document.getElementById('result_' + element_id);
    		result.style.display = 'block';   
			frontend.style.display = 'none';
    
	  	}

	};

	xhttp.open('POST', '/wp-admin/admin-ajax.php', true);
	xhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
	xhttp.send('action=lazy_quiz_ajax&parameter='+arg+'&nonce=' + nonce);

}

	let youtube = document.querySelectorAll(".youtube");

	for (let i = 0; i < youtube.length; i++) {

	  // Play video on click
	  youtube[i].addEventListener("click", function (event) {
		event.stopPropagation();
		let iframe = document.createElement("iframe");

		let source = "https://www.youtube.com/embed/" + youtube[i].dataset.embed + "?&autoplay=1&enablejsapi=1";

		var subtitles = youtube[i].dataset.subtitles;
        if (subtitles !== '') {
            source += "&cc_load_policy=1&cc_lang_pref="+subtitles+"&hl="+subtitles;
        }

		// Get Matomo title
        let matomoTitle = youtube[i].dataset.videoTitle || '';

		iframe.setAttribute("frameborder", "0");
		iframe.setAttribute("allowfullscreen", "");
		iframe.setAttribute("enablejsapi", "true");
		iframe.setAttribute("src", source);
		iframe.setAttribute("data-matomo-resource",source);
		iframe.setAttribute("data-matomo-title", matomoTitle); // Matomo title


		this.innerHTML = "";
		this.appendChild(iframe);

		//Matomo rescan
		try {
			Matomo.MediaAnalytics.scanForMedia();
		} catch (error) {
			//console.error("An error occurred while scanning for media:", error);
			// You can add additional error handling logic here if needed
		}

	  });

	}
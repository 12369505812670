jQuery(document).ready(function($) {
	if($('.sidebar #toc').length) {
		$(window).scroll(function(){
			var scrollTop = $(document).scrollTop();
			var anchors = $('body').find('section');

			for (var i = 0; i < anchors.length; i++){
				if (scrollTop > $(anchors[i]).offset().top - 300 && scrollTop < $(anchors[i]).offset().top + $(anchors[i]).height() - 300) {
					$('.toc li a[href="#' + $(anchors[i]).attr('id') + '"]').parent('li').addClass('is-active');
				} else {
					$('.toc li a[href="#' + $(anchors[i]).attr('id') + '"]').parent('li').removeClass('is-active');
				}
			}
		});
	};

	if($('#ez-toc-container').length) {
		$(window).scroll(function(){
			var scrollTop = $(document).scrollTop();
			var anchors = $('body').find('.ez-toc-section');

			for (var i = 0; i < anchors.length; i++){
				var nextSection = anchors[i+1];

				//Check if we are on the very last section, in that case there is not another section to calculate
				if(nextSection != null) {
					var positionCheck = scrollTop > $(anchors[i]).offset().top - 200 && scrollTop < $(nextSection).offset().top + $(nextSection).height() - 200;
				} else {
					var positionCheck = scrollTop > $(anchors[i]).offset().top - 200;
				}

				if(positionCheck) {
					//make sure the class does not already exist to avoid re-adding it
					if(!$('.ez-toc-list li a[href="#' + $(anchors[i]).attr('id') + '"]').parent('li').hasClass('is-active')) {
						$('.ez-toc-list li').removeClass('is-active');
						$('.ez-toc-list li a[href="#' + $(anchors[i]).attr('id') + '"]').parent('li').addClass('is-active');
					}
				}
			}
		});

		// Mobile
		// Read ez toc links and append them to a select
		var options = '';
		$('.sidebar #ez-toc-container li a').each(function () {
		    var text  = $(this).text();
		    var url   = $(this).attr('href');
		    var depth = $(this).parent().parents('ul').length;
		    var depthChar = '';
		    var i = 1;

		    for (i; i < depth; i++) {
		    	depthChar += '&ndash;&nbsp;';
		    }

		    options += '<option value="'+ url +'">' + depthChar + text + '</option>';
		});
		$('#toc-select').append(options);

		// Mobile
		// Jump to section on select change
		$('#toc-select').bind('change', function () {
			$("html, body").animate({scrollTop: $($(this).val()).offset().top - 50});
		});
	};

	// Mobile
	// Close TOC nav on click
	$('.review-sidebar-nav--headroom .toc .toc__item').click(function(){
		$('.toc').removeClass('is-active');
		$('.sidebar__btn').removeClass('is-active');
		$('.sidebar__btn').attr('aria-expanded','false');
	});
});

//  window.addEventListener('DOMContentLoaded', () => {

//  	const pluginObserver = new IntersectionObserver(entries => {
//  		entries.forEach(entry => {
//  			const id = entry.target.getAttribute('id');
//  			if (entry.intersectionRatio > 0) {
//  				var selector = document.querySelector(`.ez-toc-list li a[href="#${id}"]`);
//  				if (selector) {
//  					selector.parentElement.classList.add('is-active');
// 				}
// 			} else {
// 				var selector = document.querySelector(`.ez-toc-list li a[href="#${id}"]`);
// 				if (selector) {
// 					selector.parentElement.classList.remove('is-active');
// 				}
// 			}
// 		});
// 	});

// 	// Track all sections that have an `id` applied
// 	document.querySelectorAll('.ez-toc-section[id]').forEach((section) => {
// 		pluginObserver.observe(section);
// 	});

// });

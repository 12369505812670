jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){

		if (document.querySelector('.feature-swiper')) {

			// Feature carousel
			new Swiper('.feature-swiper', {
				slidesPerView: "auto",
				spaceBetween: 20,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				breakpoints: {
					1024: {
						spaceBetween: 100,
					},
				},
			});

		}

		if (document.querySelector('.image-swiper')) {

			// Feature panel
			new Swiper('.image-swiper', {
				slidesPerView: "auto",
				spaceBetween: 100,
				navigation: {
					nextEl: '.image-swiper-button-next',
					prevEl: '.image-swiper-button-prev',
				},
			});

		}

		if (document.querySelector('.team-swiper')) {

			// Team panel
			new Swiper('.team-swiper', {
				slidesPerView: "auto",
				spaceBetween: 30,
				autoHeight: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});

		}

		if (document.querySelector('.meet-the-team-swiper')) {

			new Swiper('.meet-the-team-swiper', {
				slidesPerView: "auto",
				spaceBetween: 30,
				autoHeight: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});

		}	

		if (document.querySelector('.gallery-thumbs-swiper')) {

			// Gallery
			var galeryThumbsSwiper = new Swiper(".gallery-thumbs-swiper", {
				spaceBetween: 5,
				slidesPerView: "auto",
				freeMode: true,
				watchSlidesProgress: true,
			});

		}
			
		if (document.querySelector('.gallery-swiper')) {

			var gallerySwiper = new Swiper(".gallery-swiper", {
				spaceBetween: 10,
				autoHeight: true,
				slidesPerView: "auto",
				thumbs: {
					swiper: galeryThumbsSwiper,
				},
			});

		}	

		if (document.querySelector('.testimonials-swiper')) {

			if (document.querySelector('.testimonials-swiper')) {

				new Swiper('.testimonials-swiper', {
					effect: "coverflow",
					centeredSlides: true,
					slidesPerView: 2,
					spaceBetween: 0,
					coverflowEffect: {
						rotate: 0,
						stretch: 0, // Increase this to stretch the active slide
						depth: 0, // Increase this to increase the depth of the "coverflow" effect
						modifier: 1,
						slideShadows: true,
						scale: 1,
					},
					navigation: {
						nextEl: '.landing-testimonials__header .swiper-button-next',
					 	prevEl: '.landing-testimonials__header .swiper-button-prev',
					},
					breakpoints: {
						1024: {
							spaceBetween: 0,
						},
						320: {
							slidesPerView: 1,
							spaceBetween: 10,
							centeredSlides: true,
							effect: "slide",
						},
					},
				});
	
			}

		}

		// Examples Section Swipper Slider
		function create_examples_slider(){

			var swiper = new Swiper(".examples-slider", {
				spaceBetween: 0,
				slidesPerView: 1,
				pagination: {
					el: ".examples-pagination",
					clickable: true,
				},
				mousewheel: false,
				keyboard: true,
				simulateTouch: false,
				// cssMode: true,
				// freeMode: true,
				navigation: {
					nextEl: ".examples-slider-next",
					prevEl: ".examples-slider-prev",
				},
			});
		}

		function filter_change( filter ){
			//console.log(filter);
			if ( filter=="all" ){
				$("[data-filter]").removeClass("non-swiper-slide")
				.addClass("swiper-slide")
				.show();
			} else {
				$(".swiper-slide").not("[data-filter='"+filter+"']")
				.addClass("non-swiper-slide")
				.removeClass("swiper-slide")
				.hide();

				$("[data-filter='"+filter+"']").removeClass("non-swiper-slide")
				.addClass("swiper-slide")
				.attr("style", null)
				.show();
			}

			create_examples_slider();
		}

		// initial run
		create_examples_slider();

		$(".examples__category-list button").on("click", function(){

			$(".examples__category-list button").removeClass("is-active");
			$(this).addClass("is-active");

			var filter = $(this).data('industry');

			filter_change(filter);

		})

		$("#example-categories-dropdown").on("change", function(){

			var filter_list = document.getElementById("example-categories-dropdown");
			var filter = filter_list.options[filter_list.selectedIndex].value;

			filter_change(filter);

		});

	}

});

function validUrl(str) {
  var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null)
}

!function (j) {

  j(document).ready(function ()
  {

    j("#img-frm").submit(function (e) {
    	if (validUrl(document.getElementById('textbox-url').value)){
            j('#loader-container').css('display','flex');
            j('#error-area').css('display','none');
        }else{
            j('#error-area').css('display','block');
            e.preventDefault();
        }
    });

    j('table').each(function() {

            var headertext = [];
            var headers = this.querySelectorAll("thead");
            var tablebody = this.querySelectorAll("tbody");

            for(var i = 0; i < headers.length; i++) {
                    headertext[i]=[];
                    for (var j = 0, headrow; headrow = headers[i].rows[0].cells[j]; j++) {
                      var current = headrow;
                      headertext[i].push(current.textContent.replace(/\r?\n|\r/,""));
                      }
            }

            //console.log(headertext);

            if (headers.length > 0) {
                    for (var h = 0, tbody; tbody = tablebody[h]; h++) {
                            for (var i = 0, row; row = tbody.rows[i]; i++) {
                              for (var j = 0, col; col = row.cells[j]; j++) {
                                col.setAttribute("data-th", headertext[0][j]);
                              }
                            }
                    }
            }

            headertext = [];

        });

    })

     
}(jQuery);

document.addEventListener('DOMContentLoaded', function() {
    var ettImages = document.querySelectorAll('.ett-magnet');
    var enabledScroll = true;

    function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        var viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        // Define the range around the center of the viewport
        var centerRange = 0.12; // 15% range around the center

        var centerY = viewportHeight / 2;
        var centerX = viewportWidth / 2;

        var rangeY = viewportHeight * centerRange;
        var rangeX = viewportWidth * centerRange;

        // Calculate the center of the element
        var elementCenterY = rect.top + rect.height / 2;
        var elementCenterX = rect.left + rect.width / 2;

        return (
            elementCenterY >= centerY - rangeY &&
            elementCenterY <= centerY + rangeY &&
            elementCenterX >= centerX - rangeX &&
            elementCenterX <= centerX + rangeX
        );
    }

    function checkImagesInView() {
        var scrollTimeout;

        
        //if ( enabledScroll) {
            for (var i = 0; i < ettImages.length; i++) {

                var image = ettImages[i];

            if (isElementInViewport(image) ) {

                image.classList.add('in-viewport');

                enabledScroll = false;
                disableScroll(); // Disable scrolling
            
                setTimeout(function() {                   
                    enableScroll();
                }, 200);  // Allow scrolling again after 1 seconds

                break;

            } else {  
                        image.classList.remove('in-viewport');
                }
            }
        //}
           
    }

    window.addEventListener('scroll', checkImagesInView);

    window.addEventListener('resize', checkImagesInView);

    // Initial check
    checkImagesInView();

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = {37: 1, 38: 1, 39: 1, 40: 1};

    function preventDefault(e) {
    e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; } 
    }));
    } catch(e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    // call this to Disable
    function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    // call this to Enable
    function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    window.addEventListener('scroll', checkImagesInView);
    }

});


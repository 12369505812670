 document.addEventListener('DOMContentLoaded', function() {

    var isRecaptchaLoaded = false;

    const inputElement = document.getElementById('ett-nl-email');
    const originalPlaceholder = inputElement.placeholder;

    // Track focus loss (blur) event
    inputElement.addEventListener('blur', function() {
    // Restore the original placeholder text when focus is lost
    inputElement.placeholder = originalPlaceholder;
    });

    inputElement.addEventListener('click', function(event) {
        inputElement.placeholder = inputElement.getAttribute('data-ph');
    });

    var emailInput = document.getElementById('ett-nl-email');
    if (emailInput) {
        emailInput.addEventListener('input', function() {
            if (!isRecaptchaLoaded) {
                var script = document.createElement('script');
                script.src = "https://www.google.com/recaptcha/api.js?hl=en";
                document.head.appendChild(script); // You can append to <body> as well
                isRecaptchaLoaded = true;
            }
        });
    }
});
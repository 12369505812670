jQuery(document).ready(function($) {
	//function to close search modal when you click on body
	$(document.body).on('click', '.global-search.is-active' ,function(){
		if(!$(event.target).closest('.global-search__window').length) {
			$('body').removeClass('search-open');
			$('.global-search').removeClass('is-active');
		}
	});

	// Move cursor to the input box
	$('.global-header__seach-btn,.nav-mobile__item .search-form__input').click(function(){
		// Search Button
		
		if ( document.getElementById('bcs_js_snippet') === null){

			function searchDelay(){

				// Wait for the button with class 'bcs-searchbox-submit' to exist in the DOM
				var intervalId = setInterval(function () {
				
					var searchButton = document.querySelector('.bcs-searchbox-submit');
				
					// Check if the button exists
					if (searchButton) {
				
						// Add the new event listener
						searchButton.addEventListener('click', bcsSearchBoxSubmit);

						clearInterval(intervalId);
					}
				}, 100);

				
			}

			function bcsSearchBoxSubmit() {
				// Get the keyword from the input field
				var keyword = document.getElementById('bcs-searchbox').value;

				if (typeof clicky.log === 'function') {
					clicky.goal( 'Search:' + keyword );
				}   
				
			}

			var search_script = document.createElement('script'); 
			var bing_id = document.getElementById('global-search-inner'); 
			var bing_src = bing_id.getAttribute('data-bing-src');
			
			search_script.setAttribute('src',bing_src);
			search_script.setAttribute('id','bcs_js_snippet');
			search_script.onload = searchDelay;

			bing_id.appendChild(search_script);

			
		};

		searchCheckingInterval = setInterval(function() { 
			if ( document.getElementById("bcs-searchbox")  !== null ){
				 document.getElementById("bcs-searchbox").focus();
				 clearInterval(searchCheckingInterval); 
			}
		}, 200);
	});

	

});
